import { EventBus } from '../EventBus';
import { Scene } from 'phaser';
import { audioButton } from './Options.js';
import { readLocally, get_top_ten, get_top_ten_offline } from './Access.js'


export class Richest extends Scene {
    constructor() {
        super('Richest');
    }

    preload() {}

    async create() {

        var gameData = await readLocally()
        var volume = gameData["volume"]
        var isChecked = gameData["mute"]

        this.cameras.main.setBackgroundColor(0x000000);

        const title = this.add.text(50, 110, 'RICH LIST', { fill: 'white', fontSize: '60px' ,strokeThickness: 1, stroke: 'white', fontFamily: 'playwritereg',padding: { right: 35}}).setAlpha(0);

      //  const descrip = this.add.text(50, 200, 'RICHEST ONLINE PLAYERS', { fill: 'white', fontSize: '20px', strokeThickness: 1, stroke: 'white', fontFamily: 'playwritereg' }).setAlpha(0);

        let data = await get_top_ten()

        const startX = 50;
        const startY = 250;
        const rowHeight = 30;
        let displayedTexts = [];
    
        this.add.text(startX, startY, 'RANK', { fill: '#fff', fontSize: '20px', fontFamily: 'playwritereg',padding: { right: 35} });
        this.add.text(startX + 100, startY, 'PLAYER', { fill: '#fff', fontSize: '20px', fontFamily: 'playwritereg',padding: { right: 35} });
        this.add.text(startX + 300, startY, 'COINS', { fill: '#fff', fontSize: '20px', fontFamily: 'playwritereg',padding: { right: 35} });

        const offline_button = this.add.text(50, 200, 'OFFLINE', { fill: '#0f0', fontSize: '30px' ,strokeThickness: 1, stroke: '#0f0', fontFamily: 'playwritereg', padding:{right:50}})
        .setInteractive()
        .on('pointerdown', async () => {
            offline_button.setStyle({ fill: '#ffff00'});
        audioButton(isChecked)
        clearOldData();
        data = await get_top_ten_offline()
        await populate_table_offline.call(this, data)
        }).on('pointerover', () => {
            offline_button.setStyle({ fill: '#ffff00' });
        })
        .on('pointerout', () => {
            offline_button.setStyle({ fill: '#0f0' });
        })

        function clearOldData() {
            displayedTexts.forEach(text => text.destroy());
            displayedTexts = []; 
        }

        const slash = this.add.text(205, 200, '/', { fill: 'white', fontSize: '30px', strokeThickness: 1, stroke: 'white', fontFamily: 'playwritereg', padding:{right:15, left:15}})

        const online_button = this.add.text(240, 200, 'ONLINE', { fill: '#0f0', fontSize: '30px' ,strokeThickness: 1, stroke: '#0f0', fontFamily: 'playwritereg', padding:{right:50}})
            .setInteractive()
            .on('pointerdown', async () => {
                online_button.setStyle({ fill: '#ffff00'});
            audioButton(isChecked)
            clearOldData();
            data = await get_top_ten()
            await populate_table_online.call(this, data)
        }).on('pointerover', () => {
            online_button.setStyle({ fill: '#ffff00' });
        })
        .on('pointerout', () => {
            online_button.setStyle({ fill: '#0f0' });
        })
        
    
        data.forEach((row, index) => {
            const y = startY + (index + 1) * rowHeight;
    
            const rankText_i = this.add.text(startX, y, row.rank.toString(), { fill: '#fff', fontSize: '18px', fontFamily: 'playwritereg', padding: { right: 35 } });
            const playerNameText_i = this.add.text(startX + 100, y, row.playerName, { fill: '#fff', fontSize: '18px', fontFamily: 'playwritereg', padding: { right: 35 } });
            const goldMultiText_i = this.add.text(startX + 300, y, row.gold_multi.toString(), { fill: '#fff', fontSize: '18px', fontFamily: 'playwritereg', padding: { right: 35 } });
            displayedTexts.push(rankText_i, playerNameText_i, goldMultiText_i);
        });
        

        async function populate_table_offline(data){

            data.forEach((row, index) => {
                const y = startY + (index + 1) * rowHeight;

      
        
                const rankText = this.add.text(startX, y, row.rank.toString(), { fill: '#fff', fontSize: '18px', fontFamily: 'playwritereg', padding: { right: 35 } });
                const playerNameText = this.add.text(startX + 100, y, row.playerName, { fill: '#fff', fontSize: '18px', fontFamily: 'playwritereg', padding: { right: 35 } });
                const goldCpuText = this.add.text(startX + 300, y, row.gold_cpu.toString(), { fill: '#fff', fontSize: '18px', fontFamily: 'playwritereg', padding: { right: 35 } });
        
                // Add text objects to the array
                displayedTexts.push(rankText, playerNameText, goldCpuText);
            });
    }

    async function populate_table_online(data){

        data.forEach((row, index) => {
            const y = startY + (index + 1) * rowHeight;

 
    
            const rankText = this.add.text(startX, y, row.rank.toString(), { fill: '#fff', fontSize: '18px', fontFamily: 'playwritereg', padding: { right: 35 } });
            const playerNameText = this.add.text(startX + 100, y, row.playerName, { fill: '#fff', fontSize: '18px', fontFamily: 'playwritereg', padding: { right: 35 } });
            const goldMultiText = this.add.text(startX + 300, y, row.gold_multi.toString(), { fill: '#fff', fontSize: '18px', fontFamily: 'playwritereg', padding: { right: 35 } });
    
            // Add text objects to the array
            displayedTexts.push(rankText, playerNameText, goldMultiText);
        });
}

        const backButton = this.add.text(350, 770, 'BACK', { fill: '#0f0', fontSize: '30px' ,strokeThickness: 1, stroke: '#0f0', fontFamily: 'playwritereg', padding:{right:50}})
        .setInteractive()
        .on('pointerdown', () => {
            backButton.setStyle({ fill: '#ffff00'});
        audioButton(isChecked)
        this.scene.start('Menu');
    })
    .on('pointerover', () => {
        backButton.setStyle({ fill: '#ffff00' });
    })
    .on('pointerout', () => {
        backButton.setStyle({ fill: '#0f0' });
    })
        this.tweens.add({
            targets: [title],    
            alpha: 1,               
            duration: 2000,        
            ease: 'Power2',    
            onComplete: function() {
            }
        });
        
        EventBus.emit('current-scene-ready', this);
    }

}
